.inter {
  font-family: 'Inter', sans-serif;
}

.landing-subtitle {
  font-size: 16px;
}

.landing-title {
  font-size: 32px;
}

.minecraft-render {
  background-image: url("./images/minecraft-render.webp");
}

.recharts-surface {
  border-radius: 6px;
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local("Inter"), url("./fonts/Inter-Bold.woff2") format("woff2");
}

@media (min-width: 576px) {
  .landing-subtitle {
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  .landing-title {
    font-size: 48px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
